import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { emitEvent } from "../socket/socket";
import axios from "../utilities/axios";
import { updateOperatorStatus } from "./operaters";

const initialState = {
  user: [],
  status: "idle",
  newAvatar: null,
  error: null,
  statusUpdateProfile: "idle",
  statusDeleteProfilePicture: "idle",
  errorUpdateProfile: null,
};

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/client/me`);
      data = response.data;
      if (response.status === 200) {
        emitEvent("CONNECT", {
          operatorId: response?.data?.data?._id,
          websiteId: response?.data?.data?.websiteID,
        });
        data.data.availability.status !== "online" &&
          thunkAPI.dispatch(
            updateOperatorStatus({
              availability: {
                status: "online",
                since: new Date().toISOString(),
              },
              websiteId: response?.data?.data?.websiteID,
            })
          );

        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (user) => {
    let data;
    try {
      const response = await axios.put(`/client/account/profile`, user);
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const DeleteProfilePic = createAsyncThunk(
  "user/DeleteProfilePic",
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(
        `/client/account/profile/profile-picture`
      );
      data = await response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchUser());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // set New Avatar
    setNewAvatar(state, action) {
      state.newAvatar = action.payload;
    },
    //after Submitting reset image
    emptyAvatar: (state, action) => {
      state.newAvatar = null;
    },
  },
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.user = action.payload.data;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [updateProfile.pending]: (state) => {
      state.statusUpdateProfile = "loading";
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.statusUpdateProfile = "succeeded";
      state.user = action.payload;
    },
    [updateProfile.rejected]: (state, action) => {
      state.statusUpdateProfile = "failed";
      state.error = action.payload;
    },
    [DeleteProfilePic.pending]: (state) => {
      state.statusDeleteProfilePicture = "loading";
    },
    [DeleteProfilePic.fulfilled]: (state, action) => {
      state.statusDeleteProfilePicture = "succeeded";
      state.user = action.payload;
    },
    [DeleteProfilePic.rejected]: (state, action) => {
      state.statusDeleteProfilePicture = "failed";
      state.error = action.payload;
    },
  },
});
export const { setNewAvatar, emptyAvatar } = slice.actions;
export const reducer = slice.reducer;
export default slice;
