import { io } from "socket.io-client";
const socket = io(process.env.REACT_APP_SOCKET_URL, {
  reconnection: true, // Enable automatic reconnection
  reconnectionAttempts: 5, // Set a maximum reconnection attempt limit
  reconnectionDelay: 1000, // Set a reconnection delay});
});

export function emitEvent(event, ...args) {
  socket.emit(event, ...args);
}

export function addEvent(eventName, eventHandler) {
  socket.removeAllListeners(eventName);
  socket.on(eventName, eventHandler);
}
export default socket;
